/* Style général pour le footer */
.footer {
    background-color: #f8f9fa;
    padding: 40px 20px;
    text-align: center;
    color: #333;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Pour rendre responsive */
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-column {
    flex: 1;
    min-width: 250px; /* Largeur minimale pour chaque colonne */
    margin: 20px;
  }
  
  .footer-column h3 {
    font-size: 20px;
    margin-bottom: 15px;
    color: #4765c3;
  }
  
  .footer-column p, .footer-column ul, .footer-column li {
    font-size: 16px;
    margin: 8px 0;
  }
  
  .footer-column ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-column ul li a {
    text-decoration: none;
    color: #333;
    transition: color 0.3s ease;
  }
  
  .footer-column ul li a:hover {
    color: #4765c3;
  }
  
  /* Icônes des réseaux sociaux */
  .social-icons {
    margin-top: 15px;
  }
  
  .social-icons a {
    margin: 0 10px;
    font-size: 20px;
    color: #333;
    transition: color 0.3s ease;
  }
  
  .social-icons a:hover {
    color: #4765c3;
  }
  
  /* Style du bas du footer */
  .footer-bottom {
    margin-top: 40px;
    border-top: 1px solid #ddd;
    padding-top: 20px;
    font-size: 14px;
    color: #777;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column; /* Empiler les colonnes */
      text-align: center;
    }
    
    .footer-column {
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .footer-column p, .footer-column ul, .footer-column li {
      font-size: 14px;
    }
  
    .social-icons a {
      font-size: 18px;
    }
  }