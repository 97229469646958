/* Partie supérieure du header avec l'information de livraison */
.header-top {
    background-color: #1a1b20;
    color: white;
    text-align: center;
    padding: 1px 0;
    font-size: 14px;
    letter-spacing: 0.5px;
    border-bottom: 1px solid #1a1b20;
    font-family: 'Cairo', sans-serif; /* Utilise une police similaire */

}

/* Partie principale du header */
.header-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ombre subtile */
    position: sticky; /* Header collant */
    top: 0;
    z-index: 1000;
}

.logo h1 a {
    font-size: 28px;
    color: #292b32;
    font-weight: 700;
    letter-spacing: 1.5px;
    margin: 0;
    text-decoration: none;
}

.logo-image {
    height: auto; /* Ajustez la hauteur selon vos besoins */
    width: 120px;  /* Gardez le ratio d'aspect */
}

.cart {
    margin-right: 20px; /* Ajustez l'espacement selon vos besoins */
}

.cart a {
    color: #292b32; /* Couleur du lien */
    text-decoration: none;
    font-size: 22px; /* Taille de l'icône */
}
.cart-login {
    display: flex;
    align-items: center;
    gap: 15px; /* Espace entre les icônes */
}

/* Style pour l'icône de connexion */
.login-icon {
    font-size: 22px;
    color: #292b32;
    cursor: pointer;
}

/* Style pour l'icône de panier */
.cart-icon {
    font-size: 22px;
    color: #292b32;
    cursor: pointer;
}

.cart-login a {
    text-decoration: none;
}

/* Menu Burger */
.menu-toggle {
    display: none; /* Cache le bouton sur les grands écrans */
    font-size: 28px;
    cursor: pointer;
    color: #292b32;
}

/* Side Drawer */
.side-drawer {
    height: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    left: -300px; /* Masque le menu en dehors de l'écran */
    width: 300px;
    z-index: 1500;
    transition: left 0.3s ease; /* Animation fluide du menu */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
}

.side-drawer.open {
    left: 0; /* Fait apparaître le menu à gauche */
}

.side-drawer ul {
    list-style: none;
    padding: 50px 20px;
}

.side-drawer ul li {
    margin: 20px 0;
}

.side-drawer ul li a {
    color: #292b32;
    text-decoration: none;
    font-size: 18px;
    display: block;
}

.side-drawer ul li a:hover {
    color: #292b32;
}

/* Bouton de fermeture */
.close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
    color: #292b32;
}

/* Overlay sombre pour l'arrière-plan lorsque le menu est ouvert */
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1400;
    transition: opacity 0.3s ease; /* Transition fluide */
}

/* Media query pour les petits écrans */
@media (max-width: 768px) {
    .logo h1 a {
        font-size: 22px;
    }
    .menu-toggle {
        display: block; /* Affiche le bouton burger sur les petits écrans */
    }
    .nav {
        display: none;
    }
    .cart {
        display: flex;
        align-items: center;
    }
}