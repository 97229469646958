/* Section des produits */
.product-section {
  max-width: 100%;
  margin: 0 auto;  /* Centrer la section dans la page */
}
/* Titre de chaque catégorie */
.section-title {
  font-size: 28px;
  margin: 30px 0;
  color: #333;
  text-align: center; /* Centrer le titre de la catégorie */
  padding-bottom: 10px;
}

.section-title span {
  border-bottom: 2px solid #4765c3; /* Trait sous le texte */
  padding-bottom: 5px; /* Espacement en bas */
}
.view-details{
  text-decoration: none;
}
/* Grille des produits */
.product-grid {
  margin: 30px;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Quatre colonnes pour PC */
  gap: 20px;
  justify-items: center;  /* Centre les produits dans les colonnes */
}

/* Carte produit */
.product-card {
  text-decoration: none;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-width: 350px; /* Largeur uniforme pour les cartes */
  padding: 10px;
  height: auto; /* Hauteur fixe pour toutes les cartes */
  display: flex;
  flex-direction: column; /* Pour empiler les éléments verticalement */
  justify-content: space-between; /* Espacer les éléments */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre pour donner de la profondeur */
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Image du produit */
.product-image {
  width: 100%;
  height: 220px; /* Ajustement pour la hauteur de l'image */
  object-fit: cover;
  border-bottom: 1px solid #ddd;
}

/* Nom du produit */
.product-name {
  font-size: 16px;
  margin: 15px 0;
  color: #333;
}

/* Prix */
.product-price {
  font-size: 16px;
  margin-bottom: 15px;
}

.old-price {
  text-decoration: line-through;
  color: #363636;
  margin-right: 10px;
}
.garantie {
  font-weight: bold;
  color: #363636;
  margin-right: 10px;
}
.bonus {
  
  font-weight: bold;
  color: #363636;
  margin-right: 10px;
}
.new-price {
  color: #49a580;
  font-weight: bold;
}

/* Évaluation des produits */
.product-rating {
  font-size: 16px;
  color: #FFD700; /* Couleur des étoiles */
  margin-top: auto; /* Élévation de l'évaluation à la fin de la carte */
  display: flex; /* Utilisation de flexbox pour l'alignement */
   /* Centrer verticalement */
}

/* Affichage du stock */
.product-stock {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
}

/* Responsive */
@media (max-width: 768px) {
  .section-title {
    font-size: 20px;
    margin: 30px 0;
    color: #333;
    text-align: center; /* Centrer le titre de la catégorie */
    padding-bottom: 10px;
  }
  .product-grid {
    grid-template-columns: repeat(2, 1fr); /* Deux colonnes pour tablettes */
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 20px;
    margin: 30px 0;
    color: #333;
    text-align: center; /* Centrer le titre de la catégorie */
    padding-bottom: 10px;
  }
  .product-grid {
    grid-template-columns: 1fr; /* Une colonne pour mobiles */
  }

  .product-card {
    max-width: 100%;
    height: auto;
  }

  .product-name {
    font-size: 16px;
  }

  .product-price {
    font-size: 14px;
  }
}

.categoryCard {
  font-size: small;
  text-decoration: none;
  color: black;
  text-align: left;
  margin: 15px 0;
}
.rating-count{
  color: black;
}

/* Banner.css */
.banner {
  position: relative; /* Position pour l'image */
  text-align: center; /* Centrer le texte */
  color: white; /* Couleur du texte */
  margin-bottom: 20px; /* Espacement inférieur */
  border-radius: 8px; /* Coins arrondis */
  overflow: hidden; /* Évite que le texte déborde de la bannière */
}

.banner-image {
  width: 100%; /* Prendre toute la largeur */
  height: 500px;
}

/* Responsive */
@media (max-width: 768px) {
  .banner-image {
    width: 100%; /* Prendre toute la largeur */
    height: 200px;
  }
}
/* Style pour le badge de promotion */
.promotion-badge {
  background-color: #4765c3; /* Couleur d'arrière-plan du badge */
  color: white; /* Couleur du texte */
  font-weight: bold; /* Texte en gras */
  padding: 5px; /* Espacement intérieur */
  border-radius: 5px; /* Coins arrondis */
  text-align: center; /* Centre le texte */
  margin: 10px 0; /* Marge en haut et en bas */
}